// https://docs.sentry.io/platforms/javascript/guides/vue/

import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup(nuxtApp) {
    const config = useRuntimeConfig()

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: config.public.SENTRY_DSN,
      //    release: config.public.CODE_VERSION,
      environment: config.public.SENTRY_ENVIRONMENT,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      //tracesSampleRate: 0.5,
      /*tracePropagationTargets: [
      'https://sanremo-staging.retuertotest.workers.dev',
      'https://www.perfumeriassanremo.es',
    ],*/
    })

    return {
      provide: {
        Sentry: () => Sentry,
      },
    }
  },
})
